<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
/**
 * Advanced-table component
 */

export default {
  page: {
    title: "ขอนุมัติเดินทาง",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    btnComponent,
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      title: "ขอนุมัติเดินทาง",
      items: [
        {
          text: "ข้อมูลหลัก",
        },
        {
          text: "การเงิน",
        },
        {
          text: "ขอนุมัติเดินทาง",
        },
      ],

      interiorColorId: "",
      localDataBranch: [],
      localData: [],
      overlayFlag: false,
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      total: "",
      rowData: [], //? Data ที่ต่อ API
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      // filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      selectedUsers: [],
      isSelectedAll: false,
      selectMode: "multi",
      selected: [],
      branchIdSearch: [],
      rowMasterBrands: [],
      branchId: [],
      toBranchId: "",
      brand: "",
      departureDate: "",
      filter: [
        {
          outsourcingCode: "",
          brands: "",
          licensePlate: "",
        },
      ],
      osId: "",

      optionsBrand: [],
      optionsVehicles: [],
      nameTh: "",
      nameEn: "",
      interiorCode: "",
      statusLabel: "",
      urlPrint: "",

      fields: [
        {
          key: "trvexpCode",
          sortable: true,
          label: "รหัสขอนุมัติเดินทาง",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ป้ายทะเบียน",
        },
        {
          key: "departureDate",
          sortable: true,
          label: "วันที่เดินทาง",
        },
        {
          key: "branchName",
          sortable: true,
          label: "จากสาขา",
        },
        {
          key: "toBranchName",
          sortable: true,
          label: "ไปยัง",
        },
        {
          key: "objective",
          sortable: true,
          label: "จุดประสงค์",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        {
          key: "action",
          label: "แก้ไข / ลบ",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    //
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.rowData.length;
  },

  created() {
    this.getLocalData();
    this.getData();
    // this.getMasterBrand();
  },
  methods: {
    getMasterBrand() {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/travel-expenses/vehicle-brand", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowMasterBrands = response.data.data;
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
      // console.log(this.localDataBranch);
    },
    handleSearch() {
      this.getData();
    },

    getData: function () {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/travel-expenses", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchId.branchId,
            toBranchId:
              this.toBranchId.branchId == null ? "" : this.toBranchId.branchId,
            departureDate: this.departureDate,

            // branchId: [this.branchId],
            // interiorCode: '',
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          //?ได้รับการตอบกลับ
          //?ถ้าได้ข้อมูลมาหรือได้รับการตอบกลับให้ทำอะไร
          // console.log(response.data.data);
          this.rowData = response.data.data; //?ข้อมูล
          // this.per_page = response.data.per_page; // ?จำนวนข้อมูลที่แสดงต่อหน้า
          // this.page = response.data.page; // ?หน้าที่เท่าไหร่
          // this.total_pages = response.data.total_pages; //?หน้าทั้งหมด
          this.rowData.total = response.data.total; //? จำนวนข้อมูลทั้งหมด
          this.statusLabel = response.data.statusLabel;
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    ConfirmDelPackage(rowData) {
      Swal.fire({
        title: "ต้องการลบข้อมูลขอนุมัติเดินทางใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.deletePackage(rowData);
        }
      });
    },

    deletePackage: function (rowData) {
      //   this.osId = rowData;
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete("api/travel-expenses/delete", {
          data: {
            trvexpId: rowData,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    print(trvexpId) {
      this.loading = true;
      useNetw
        .get("api/travel-expenses/print", {
          params: {
            trvexpId: trvexpId,
          },
        })
        .then((response) => {
          this.urlPrint = response.data.url;
          window.location = (this.urlPrint);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    onSearch() {
      if (
        this.branchIdSearch == "" ||
        this.branchIdSearch == undefined ||
        this.branchIdSearch.length == 0
      ) {
        this.branchId = this.localData[0].branchId;
      } else {
        this.branchId = this.branchIdSearch.branchId;
      }
      // console.log(this.branchId);
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-skeleton-table
          :rows="5"
          :columns="6"
          :table-props="{ bordered: false, striped: true }"
          animation="throb"
        ></b-skeleton-table>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">ข้อมูลแพคเกจในระบบ</h4> -->

              <div class="row">
                <div class="col-md-4">
                  <div class="mb-3 position-relative">
                    รหัสขอนุมัติเดินทาง
                    <input
                      type="text"
                      class="form-control"
                      v-model="interiorCode"
                      placeholder="Search..."
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="mb-3 position-relative">
                    จากสาขา
                    <multiselect
                      v-model="branchId"
                      label="nameTh"
                      type="search"
                      :options="localData"
                      open-direction="bottom"
                      placeholder="สาขา"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3 position-relative">
                    ไปที่สาขา
                    <multiselect
                      v-model="toBranchId"
                      label="nameTh"
                      type="search"
                      :options="localData"
                      open-direction="bottom"
                      placeholder="สาขา"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="mb-3 position-relative">
                    วันเดินทาง
                    <date-picker
                      v-model="departureDate"
                      format="YYYY-MM-DD"
                      value-type="format"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-md-12 text-end">
                  <btnComponent
                    @click="handleSearch"
                    changeStyle="search"
                  ></btnComponent
                  >&nbsp;
                  <router-link :to="{ name: 'travel-expenses-form' }">
                    <button class="btn btn-primary">
                      <i class="uil uil-plus"> </i>เพิ่ม
                    </button>
                  </router-link>
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      แสดงผล&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp;รายการ
                    </label>
                  </div>
                </div>
              </div>
              <!-- End search -->
              <b-overlay
                :show="overlayFlag"
                :spinner-variant="this.$overlayVariant"
                :spinner-type="this.$overlayType"
                :rounded="this.$overlayRounded"
              >
                <tableData :fields="fields" :items="rowData">
                  <template #cell(status)="rowData">
                    <span
                      class="badge bg-warning font-size-12 ms-2"
                      v-if="rowData.item.status === 'DFT'"
                    >
                      {{ statusLabel.DFT }}
                    </span>
                    <span
                      class="badge bg-info font-size-12 ms-2"
                      v-if="rowData.item.status === 'SUBMIT'"
                    >
                      {{ statusLabel.SUBMIT }}
                    </span>
                    <span
                      class="badge bg-success font-size-12 ms-2"
                      v-if="rowData.item.status === 'BAPV'"
                    >
                      {{ statusLabel.BAPV }}
                    </span>
                    <span
                      class="badge bg-danger font-size-12 ms-2"
                      v-if="rowData.item.status === 'NAPV'"
                    >
                      {{ statusLabel.NAPV }}
                    </span>
                    <span
                      class="badge bg-success font-size-12 ms-2"
                      v-if="rowData.item.status === 'APV'"
                    >
                      {{ statusLabel.APV }}
                    </span>
                    <span
                      class="badge bg-danger font-size-12 ms-2"
                      v-if="rowData.item.status === 'CANCEL'"
                    >
                      {{ statusLabel.CANCEL }}
                    </span>
                  </template>
                  <template #cell(action)="rowData">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <router-link
                          href="javascript:void(0);"
                          class="px-2 text-primary"
                          v-b-tooltip.hover
                          :to="{
                            name: 'travel-expenses-edit',
                            params: {
                              trvexpId: rowData.item.trvexpIdBase64,
                            },
                          }"
                          title="Edit"
                        >
                          <i class="uil uil-pen font-size-18"></i>
                        </router-link>
                      </li>
                      <li class="list-inline-item">
                        <a
                          href="javascript:void(0);"
                          class="px-2 text-danger"
                          v-b-tooltip.hover
                          title="Delete"
                          @click="ConfirmDelPackage(rowData.item.trvexpId)"
                        >
                          <i class="uil uil-trash font-size-18"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          href="javascript:void(0);"
                          class="px-2 text-success"
                          v-b-tooltip.hover
                          title="Print"
                          @click="print(rowData.item.trvexpId)"
                        >
                          <i class="uil uil-print font-size-18"></i>
                        </a>
                      </li>
                    </ul>
                  </template>
                </tableData>
                <div class="row">
                  <div class="col">
                    <div class="align-items-center">
                      แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                      {{ this.rowData.total }} รายการ
                    </div>
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rowData.total"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>
  </Layout>
</template>
